import React from "react"
import { graphql } from "gatsby"
import SnackbarProvider from "react-simple-snackbar"

import {
  Layout,
  Seo,
  RequestACallbackForm,
  RequestACallbackConfirmation,
} from "../components/Shared"

const RequestACallback = ({ data }) => {
  const [callbackSubmitted, setCallbackSubmitted] = React.useState(false)
  const [callbackData, setCallbackData] = React.useState(null)
  return (
    <Layout minimal={true}>
      <Seo
        url={data.site && data.site.siteMetadata.url + "/request-a-callback"}
        title={data.seo && data.seo.title}
        description={data.seo.metaDescription.metaDescription}
        image={
          data.seo &&
          data.seo.image &&
          data.seo.image.file &&
          data.seo.image.file.url
        }
      />

      <SnackbarProvider>
        {callbackSubmitted && callbackData ? (
          <RequestACallbackConfirmation callbackData={callbackData} />
        ) : (
          <RequestACallbackForm
            setCallbackSubmitted={setCallbackSubmitted}
            setCallbackData={setCallbackData}
          />
        )}
      </SnackbarProvider>
    </Layout>
  )
}

export default RequestACallback

export const pageQuery = graphql`
  query getRequestCallbackSeo {
    site {
      siteMetadata {
        url
      }
    }
    seo: contentfulSeo(contentful_id: { eq: "5GPksv0RloydZlVnmvogrO" }) {
      image {
        fluid {
          src
        }
        title
        description
      }
      title
      metaDescription {
        metaDescription
      }
    }
  }
`
